<template>
<div>
    <v-col cols="12" sm="4">
        <h4>Listado de Mutualistas</h4>
    </v-col>
    <v-row align="center" class="list px-3 mx-auto">
        <v-col cols="12" sm="8">
            <v-text-field v-model="searchMutualista" label="Buscar por Nombre"></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
            <v-btn @click="page = 1; retrieveMutualistas();">
                Search
            </v-btn>
        </v-col>
        <v-col cols="12" sm="12">
            <v-row>
                <v-col cols="4" sm="3">
                    <v-select v-model=" pageSize " :items=" pageSizes " label="Mutualistas por Pagina" @change=" handlePageSizeChange "></v-select>
                </v-col>
                <v-col cols="12" sm="9">
                    <v-pagination v-model=" page " :length=" totalPages " total-visible="7" next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input=" handlePageChange "></v-pagination>
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" sm="12">
            <v-card class="mx-auto" tile>
                <v-card-title>Mutualistas</v-card-title>
                <v-data-table :headers=" headers " :items=" mutualistas " disable-pagination :hide-default-footer=" true " :custom-sort=" customSort ">
                    <template v-slot:[`item.actions`]=" { item } ">
                        <v-btn icon color="blue" class="mr-2" @click=" editarMutualista(item.id) ">
                            <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn color="red lighten-2" dark icon @click=" (deleteConfirmation(item.id)) ">
                             <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                        <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>

<script>
import MutualistaDataService from "../services/MutualistaDataService";
import ConfirmDialogue from '../components/ConfirmDialogue.vue';

export default {

    name: "mutualistas-list",
    components: {
        ConfirmDialogue
    },
    data() {
        return {
            dialog: false,
            searchMutualista: "",
            mutualistas: [],
            headers: [{
                    text: "Nombre",
                    align: "start",
                    sortable: true,
                    value: "nombre"
                },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false
                },
            ],

            page: 1,
            totalPages: 0,
            pageSize: 12,
            pageSizes: [12, 24, 48],
        };
    },
    methods: {

        async deleteConfirmation(idToDelete) {
            const ok = await this.$refs.confirmDialogue.show({
                title: 'Desea eliminar la Mutualista?',
                message: 'Esta accion no se puede deshacer.',
                okButton: 'Eliminar',
            })
            // If you throw an error, the method will terminate here unless you surround it wil try/catch
            if (ok) {
                this.deleteMutualista(idToDelete)
            } else {
                //    alert('You chose not to delete this page. Doing nothing now.')
            }
        },

        deleteMutualista(id) {
            MutualistaDataService.delete(id)
                .then(() => {
                    this.refreshList();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        //customSort: the native sort function of the data view table doesn't works as it should, this custom implementation solves it
        customSort: function (items, index, isDesc) {
            items.sort((a, b) => {
                if (index[0] == 'date') {
                    if (!isDesc[0]) {
                        return new Date(b[index]) - new Date(a[index]);
                    } else {
                        return new Date(a[index]) - new Date(b[index]);
                    }
                } else {
                    if (typeof a[index] !== 'undefined') {
                        if (!isDesc[0]) {
                            return a[index].toLowerCase().localeCompare(b[index].toLowerCase());
                        } else {
                            return b[index].toLowerCase().localeCompare(a[index].toLowerCase());
                        }
                    }
                }
                console.log(items);
            });
            return items;

        },
        getRequestParams(searchMutualista, page, pageSize) {
            let params = {};
            if (searchMutualista) {
                params["nombre"] = searchMutualista;
            }
            if (page) {
                params["page"] = page - 1;
            }
            if (pageSize) {
                params["size"] = pageSize;
            }
            return params;
        },
        retrieveMutualistas() {
            const params = this.getRequestParams(
                this.searchMutualista,
                this.page,
                this.pageSize
            );
            MutualistaDataService.getAll(params)
                .then((response) => {
                    const {
                        mutualistas,
                        totalPages
                    } = response.data;
                    this.mutualistas = mutualistas.map(this.getDisplayMutualista);
                    this.totalPages = totalPages;
                    console.log(response.data);
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        handlePageChange(value) {
            this.page = value;
            this.retrieveMutualistas();
        },
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.page = 1;
            this.retrieveMutualistas();
        },
        getDisplayMutualista(mutualista) {
            return {
                id: mutualista.id,
                nombre: mutualista.nombre.substr(0, 40),
                fechaupdate: mutualista.updatedAt.substring(0, 10),
            };
            
        },
        refreshList() {
            this.retrieveMutualistas();
        },
        editarMutualista(id) {
            this.$router.push({
                name: "mutualistas-edit",
                params: {
                    id: id
                }
                
            });
        },
    },
    mounted() {
        this.retrieveMutualistas();
    },
};
</script>

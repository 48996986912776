import http from "../http-common";

class MutualistaDataService {
  getAll(params) {
    return http.get("/mutualista", {
      params,
    });
  }

  get(id) {
    return http.get(`/mutualista/${id}`);
  }

  create(data) {
    return http.post("/mutualista", data);
  }

  update(id, data) {
    return http.put(`/mutualista/${id}`, data);
  }

  delete(id) {
    return http.delete(`/mutualista/${id}`);
  }
}

export default new MutualistaDataService();
